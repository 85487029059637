import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/dist/cjs.js??ref--14-1!../node_modules/postcss-loader/dist/cjs.js??ref--14-2!../node_modules/resolve-url-loader/index.js??ref--14-3!../node_modules/sass-loader/dist/cjs.js??ref--14-4!./custom-theme.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};